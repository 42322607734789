import { useState, useEffect } from 'react';

const useScrollProgress = (maxScroll = 72) => {
  if (typeof window === 'undefined') return null;

  const [scrollProgress, setScrollProgress] = useState(0);

  useEffect(() => {
    const handleScroll = () => {
      const progress = Math.min(window.scrollY / maxScroll, 1);
      setScrollProgress(progress);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [maxScroll]);

  return scrollProgress;
};

export default useScrollProgress;
